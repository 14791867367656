import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import "./StudentArena.css";
const StudentArena = () => {
  return (
    <div className="inner-body">
      <Container fluid>
        <Row className="inner">
          <div className="inner-head-outer">
            <div className="inner-head">
              <Col>
                <h2>Student Arena</h2>
                <Breadcrumb>
                  <Breadcrumb.Item href="../">
                    <i className="fa fa-home"></i> Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Student Arena</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <div className="mib relative">
        <div className="leftline"></div>

        <div className="bottomline"></div>
        <Container>
          <Row>
            <Col>
              <p className="text-center">
                To ensure healthy and constructive competition among the
                students and to develop team spirit the students are divided
                under four group:
              </p>
              <div className="circlearea">
                <div className="circle">Alfa</div>
                <div className="circle">Bravo</div>
                <div className="circle">Chetak</div>
                <div className="circle">Delta</div>
              </div>

            </Col>
          </Row>
          <Row>
                  <Col md={3}>
                    <div className="saarea">
                    <img
                        src="https://www.mpsckp.com/images/2024hb.jpg"
                        alt="Head Boy"
                      />
                      <div className="saareadetails">
                      <p>SAMBHAV NANDA</p>
                      <p>Head Boy</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="saarea">
                    <img
                        src="https://www.mpsckp.com/images/2024hg.jpg"
                        alt="Head Girl"
                      />
                      <div className="saareadetails">
                        <p>SWETA BODRA</p>
                        <p>Head Girl</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="saarea">
                    <img
                        src="https://www.mpsckp.com/images/2024vhb.jpg"
                        alt="Vice Head Boy"
                      />
                      <div className="saareadetails">
                        <p>SHIV SHANKAR BOBONGA</p>
                        <p>Vice Head Boy</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="saarea">
                    <img
                        src="https://www.mpsckp.com/images/2024vhg.jpg"
                        alt="Vice Head Girl"
                      />
                      <div className="saareadetails">
                        <p>LUCKY SAHU</p>
                        <p>Vice Head Girl</p>
                      </div>
                    </div>
                  </Col>
                </Row>
        </Container>
      </div>
    </div>
  );
};

export default StudentArena;
